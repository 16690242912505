@import "../../style/variables";
@import "../../style/mediaQueries";
.container-navbar {
    display: flex;
    justify-content: space-between;
    background-color: $platinum;
    padding: 2em;
    font-size: 1.2em;
    border-bottom: 10px solid $white;
    border-bottom-right-radius: 10px;
    ul > * {
        display: inline;
        padding: 0.4em;
    }
    img {
        width: 250px;
    }

    @include for-phone-only {
        img {
            width: 100px !important;
        }
    }
    @include for-tablet-portrait-up {
        img {
            width: 125px !important;
        }
    }
    @include for-tablet-landscape-up {
        img {
            width: 125px !important;
        }
    }
    @include for-big-desktop-up {
        img {
            width: 150px !important;
        }
    }
}

.logo {
    text-decoration: none;
}
