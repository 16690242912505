@import "../../../style/_mediaQueries.scss";

.pricing-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5em;
    h1 {
        font-size: 1.7em;
    }
    ul {
        font-size: 1.6em;
        padding: 3em;
        // list-style-type: square;
        width: 40%;
    }
    h2 {
        font-size: 1.6em;
    }
    li {
        font-size: 0.7em;
        text-align: left;
        padding: 0.2em 0.2em 0.2em 0.4em;
    }
    ul > :first-child {
        padding-top: 2em;
    }

    @include for-phone-only {
        padding-top: 2em;
        padding-bottom: 3em;
        overflow-y: scroll;
        ul {
            width: 80%;
            padding: 1em;
            text-align: center;
        }
        ul > :first-child {
            padding-top: 1em;
        }
        h1{
            width: 80%;
            text-align: center;
        }
        li {
            font-size: 0.7em;
            text-align: center ;
            padding: 0.2em 0.2em 0.2em 0.4em;
        }
    }
    @include for-tablet-portrait-up {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 2em;
        padding-bottom: 3em;
        ul {
            width: 80% !important;
            padding: 0.4em !important ;
           
        }
        ul > :first-child {
            padding-top: 1em;
        }
        li{
            text-align: center !important;
            padding: 0.3em;
            font-size: 0.9em;
        }
    }
    @include for-tablet-landscape-up{
        h1{
            font-size: 3em !important;
        }
        h2{
            font-size: 3em !important;
        }
        ul{
        font-size: 2em !important;
        }
        ul > :first-child {
            padding-top: 1em;
        }
        li{
            text-align: center !important;
            padding: 0.3em;
            font-size: 0.9em;
        }
    }
}
