@import "../../style/variables";
@import "../../style/mediaQueries";

.footer-container {
    display: flex;
    justify-content: space-evenly;
    padding: 0.2em;
    background-color: $platinum;
    p{
        padding: 0.5em;
    }
    @include for-big-desktop-up {
        font-size: 1.2em;
    }
}
