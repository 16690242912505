@import "../../../style/_mediaQueries.scss";
@import "../../../style/_variables.scss";

.navigation-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 20% auto;
    flex: 1;
    flex-direction: column;
    @include for-phone-only {
        // color:blue;
        display: flex;
        overflow-y: scroll;
    }
    @include for-tablet-portrait-up {
        // color:blue;
        overflow-x: hidden;

        display: flex;
        flex: 1;
        overflow-y: auto;
    }
    @include for-desktop-up {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 200px auto;
    }
    @include for-big-desktop-up {
    }
}

.panel {
    grid-row: 2;
    flex: 1;
    position: relative;

    a {
        font-size: 3em;
        width: 100%;
        display: flex;
        justify-content: center;
        color: $orange-web;
        background-color: rgba(26, 5, 5, 0.5);
        position: absolute;
        padding: 0.5em 0 0.5em 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    a:hover {
        transition: 1s linear;
        color: white;
    }
    a::after {
        content: "";
        width: 0px;
        height: 1px;
        display: block;
        background: white;
        transition: 300ms;
    }
    // a:hover::after {
    //     width: 100%;
    // }
    @include for-phone-only {
        min-height: 300px !important;
        a {
            font-size: 2em;
        }
    }
    @include for-tablet-portrait-up {
        min-height: 300px !important;
        a {
            font-size: 2em;
        }
    }
    @include for-desktop-up {
        min-height: 500px !important;
    }
}

@keyframes swingright {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes swingleft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.dangleleft {
    animation: swingleft 1s ease-in forwards;
}

.dangleright {
    animation: swingright 1s ease-in forwards;
}

.header {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-phone-only {
        // color:blue;
        h1 {
            font-size: 1.5em !important;
            padding: 0.7em !important;
        }
        p {
            text-align: justify;

            width: 50%;
            font-size: 1em !important;
            padding: 0.2em;
        }
    }

    @include for-tablet-portrait-up {
        p {
            text-align: center;
        }
    }

    h1 {
        font-size: 2em;
        padding: 0.7em;
    }
    p {
        font-size: 1.5em;
    }

    @include for-tablet-landscape-up {
        h1 {
            font-size: 3em;
            padding: 0.3em;
        }
        p {
            padding: 0.5em;
        }
    }
}
