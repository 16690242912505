@import "../../../style/_mediaQueries.scss";

.contact-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    form {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 5em;
        justify-content: center;

        width: 50%;
    }
    input,
    label,
    textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 1em;
    }
    label{
        padding-left:0;
        padding-right:0;

    }
    input,
    textarea {
        margin-top: 1em;
    }
    h1 {
        font-size: 2em;
        padding: 1em 0 1em 0;
    }
    input[type="submit"] {
        width: 100px;
        align-self: center;
  
    }
    label,
    p {
        font-size: 1.2em;
    }
    p {
        align-self: center;
    }
    .padding1em {
        padding: 1em;
    }
    .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }
    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    .captcha{
        display:flex;
        justify-content: center;
    }

    @include for-phone-only {
        overflow-y: scroll;
        form {
            top: 1em;
            justify-content: center;
            font-size: 1.2em;
            width: 60%;
        }
    }
    @include for-tablet-portrait-up{
        overflow-y: auto;
        form {
            top: 1em;
            justify-content: center;
            font-size: 1.2em;
            width: 60%;
        }
    }
}
