@import "../../../style/_mediaQueries.scss";
@import "../../../style/_variables.scss";

.container-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    flex: 1;
    grid-auto-flow: dense;
    gap: 1em;
   
    @include for-phone-only {
        // color:blue;
        overflow-y: scroll !important;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 4em;
        align-items: center;
        text-align: center;
        ul > :first-child {
            padding-top: 0em !important;
        }
        p {
            width: 80% !important;
        }
        ul {
            width: 80% !important;
        }
        li {
            padding-left: 0 !important;
        }
    }
    @include for-tablet-portrait-up {
        overflow-y: auto;
        display: grid !important;
        text-align: center;
        grid-template-columns: 1fr !important;
        grid-template-rows: auto auto 1fr;
        h2 {
            padding: 0 !important;
        }
        p {
            width: 80% !important;
        }
        ul {
            width: 80% !important;
            padding-bottom: 1em;
        }
        img {
            max-height: 500px !important;
        }
    }
    @include for-desktop-up {
        display: grid;
        grid-template-columns: auto auto !important;
        grid-template-rows: auto auto 1fr !important;
        .info {
            grid-column: 1 / span 2;
        }
        .flex-start {
            align-self: center !important;
            justify-self: center !important;
        }
        img {
            max-height: 500px !important;
            aspect-ratio: 2/1 !important;
        }
        ul {
            grid-column: 1 / span 2;
            font-size: 1.2em !important;
        }
        p {
            font-size: 1.5em !important;
        }
        h1 {
            padding: 1rem !important;
        }
    }
    @include for-big-desktop-up{
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: 300px auto  !important;
        width: 70%;
        align-self: center;
        flex: 1;
        margin-top: 4em;
        .info {
            grid-column: 1;
            text-align: left;
            justify-content: start;
        }
        .flex-start{
            justify-self: baseline !important;
        }
        p{
            align-self: start !important;
        }
        ul {
            grid-column:2;
            font-size: 1.2em !important;
        }
        .noalign{
            align-self: flex-start !important;
            justify-self: start;
        }
        h1{
            padding: 0 !important;
        }
    }

    h1 {
        font-size: 2.5em;
        // grid-column: 1 / span 2;
        padding: 1em;
    }
    p {
        // width: 30%;
        justify-self: center;
        align-self: center;
        font-size: 1.2em;
        width: 60%;
    }
    img {
        // max-width: 100%;

        justify-self: center;
        // height: auto;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        width: 60%;
        justify-self: center;
        align-self: center;
        border-radius: 3px;
    }

    .flex-start {
        align-self: start !important;
    }

    .info {
        display: flex;
        flex-direction: column;
    }
    ul {
        font-size: 1em;
        // list-style-type: square;
        display: flex;
        flex-direction: column;
        justify-self: center;
        width: 60%;
    }

    h2 {
        font-size: 1.7em;
        padding-bottom: 1em;
    }
    li {
        // font-size: 1em;
        align-self: right;

        padding: 0.2em 0.2em 0.2em 3%;
    }
    ul > :first-child {
        padding-top: 2em;
    }
    li::before {
        content: "– ";
    }
}
